import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as indexzjaNqSEaloMeta } from "/opt/build/repo/pages/booking/add-on/index.vue?macro=true";
import { default as _91attributeUuid_93JkdyEfddUBMeta } from "/opt/build/repo/pages/booking/attribute/[attributeUuid].vue?macro=true";
import { default as basketuAF7jOR4yUMeta } from "/opt/build/repo/pages/booking/basket.vue?macro=true";
import { default as _91_91categoryUuid_93_93IFKN4S7EudMeta } from "/opt/build/repo/pages/booking/category/[[categoryUuid]].vue?macro=true";
import { default as checkoutZdnSZSWP2SMeta } from "/opt/build/repo/pages/booking/checkout.vue?macro=true";
import { default as confirmationF4sNVQClRwMeta } from "/opt/build/repo/pages/booking/confirmation.vue?macro=true";
import { default as end_45dateQDp4Nm2NzXMeta } from "/opt/build/repo/pages/booking/end-date.vue?macro=true";
import { default as _91categoryUuid_93TsOfBrEKmkMeta } from "/opt/build/repo/pages/booking/equipment-type/[categoryUuid].vue?macro=true";
import { default as locationSRRwl3dT3aMeta } from "/opt/build/repo/pages/booking/location.vue?macro=true";
import { default as handlerVKuyxUG6vZMeta } from "/opt/build/repo/pages/booking/payment/handler.vue?macro=true";
import { default as indexT5luUSProvMeta } from "/opt/build/repo/pages/booking/payment/index.vue?macro=true";
import { default as _91orderReference_93ANNDcum6L0Meta } from "/opt/build/repo/pages/booking/regenerate/[orderReference].vue?macro=true";
import { default as start_45datewkjdeSTTbGMeta } from "/opt/build/repo/pages/booking/start-date.vue?macro=true";
import { default as contact_45usM8CpKpYLVzMeta } from "/opt/build/repo/pages/contact-us.vue?macro=true";
import { default as _91equipmentTypeSlug_93YSNFqb7rtSMeta } from "/opt/build/repo/pages/equipment/[equipmentTypeSlug].vue?macro=true";
import { default as _91attributeValueSlug_934MubDlmLlUMeta } from "/opt/build/repo/pages/equipment/attributes/[categorySlug]/[attributeSlug]/[attributeValueSlug].vue?macro=true";
import { default as _91_46_46_46categorySlug_93cYrRQsjETLMeta } from "/opt/build/repo/pages/equipment/categories/[...categorySlug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91articleSlug_93abgr3m7ko9Meta } from "/opt/build/repo/pages/knowledge/[articleSlug].vue?macro=true";
import { default as _91_46_46_46slug_93zjsHwrE3mBMeta } from "/opt/build/repo/pages/knowledge/c/[...slug].vue?macro=true";
import { default as my_45bookinghptbBivJSvMeta } from "/opt/build/repo/pages/my-booking.vue?macro=true";
import { default as _91slug_93nVEWT12ylwMeta } from "/opt/build/repo/pages/partners/[slug].vue?macro=true";
import { default as _91_46_46_46slug_93JqrI17zkodMeta } from "/opt/build/repo/pages/pl/[...slug].vue?macro=true";
import { default as _91email_93gYDxd8wzsUMeta } from "/opt/build/repo/pages/supplier/manage-booking/[orderReference]/[email].vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "booking-add-on",
    path: "/booking/add-on",
    meta: indexzjaNqSEaloMeta || {},
    component: () => import("/opt/build/repo/pages/booking/add-on/index.vue")
  },
  {
    name: "booking-attribute-attributeUuid",
    path: "/booking/attribute/:attributeUuid()",
    meta: _91attributeUuid_93JkdyEfddUBMeta || {},
    component: () => import("/opt/build/repo/pages/booking/attribute/[attributeUuid].vue")
  },
  {
    name: "booking-basket",
    path: "/booking/basket",
    meta: basketuAF7jOR4yUMeta || {},
    component: () => import("/opt/build/repo/pages/booking/basket.vue")
  },
  {
    name: "booking-category-categoryUuid",
    path: "/booking/category/:categoryUuid?",
    meta: _91_91categoryUuid_93_93IFKN4S7EudMeta || {},
    component: () => import("/opt/build/repo/pages/booking/category/[[categoryUuid]].vue")
  },
  {
    name: "booking-checkout",
    path: "/booking/checkout",
    meta: checkoutZdnSZSWP2SMeta || {},
    component: () => import("/opt/build/repo/pages/booking/checkout.vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking/confirmation",
    meta: confirmationF4sNVQClRwMeta || {},
    component: () => import("/opt/build/repo/pages/booking/confirmation.vue")
  },
  {
    name: "booking-end-date",
    path: "/booking/end-date",
    meta: end_45dateQDp4Nm2NzXMeta || {},
    component: () => import("/opt/build/repo/pages/booking/end-date.vue")
  },
  {
    name: "booking-equipment-type-categoryUuid",
    path: "/booking/equipment-type/:categoryUuid()",
    meta: _91categoryUuid_93TsOfBrEKmkMeta || {},
    component: () => import("/opt/build/repo/pages/booking/equipment-type/[categoryUuid].vue")
  },
  {
    name: "booking-location",
    path: "/booking/location",
    meta: locationSRRwl3dT3aMeta || {},
    component: () => import("/opt/build/repo/pages/booking/location.vue")
  },
  {
    name: "booking-payment-handler",
    path: "/booking/payment/handler",
    meta: handlerVKuyxUG6vZMeta || {},
    component: () => import("/opt/build/repo/pages/booking/payment/handler.vue")
  },
  {
    name: "booking-payment",
    path: "/booking/payment",
    meta: indexT5luUSProvMeta || {},
    component: () => import("/opt/build/repo/pages/booking/payment/index.vue")
  },
  {
    name: "booking-regenerate-orderReference",
    path: "/booking/regenerate/:orderReference()",
    meta: _91orderReference_93ANNDcum6L0Meta || {},
    component: () => import("/opt/build/repo/pages/booking/regenerate/[orderReference].vue")
  },
  {
    name: "booking-start-date",
    path: "/booking/start-date",
    meta: start_45datewkjdeSTTbGMeta || {},
    component: () => import("/opt/build/repo/pages/booking/start-date.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/opt/build/repo/pages/contact-us.vue")
  },
  {
    name: "equipment-equipmentTypeSlug",
    path: "/equipment/:equipmentTypeSlug()",
    component: () => import("/opt/build/repo/pages/equipment/[equipmentTypeSlug].vue")
  },
  {
    name: "equipment-attributes-categorySlug-attributeSlug-attributeValueSlug",
    path: "/equipment/attributes/:categorySlug()/:attributeSlug()/:attributeValueSlug()",
    component: () => import("/opt/build/repo/pages/equipment/attributes/[categorySlug]/[attributeSlug]/[attributeValueSlug].vue")
  },
  {
    name: "equipment-categories-categorySlug",
    path: "/equipment/categories/:categorySlug(.*)*",
    component: () => import("/opt/build/repo/pages/equipment/categories/[...categorySlug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "knowledge-articleSlug",
    path: "/knowledge/:articleSlug()",
    component: () => import("/opt/build/repo/pages/knowledge/[articleSlug].vue")
  },
  {
    name: "knowledge-c-slug",
    path: "/knowledge/c/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/knowledge/c/[...slug].vue")
  },
  {
    name: "my-booking",
    path: "/my-booking",
    component: () => import("/opt/build/repo/pages/my-booking.vue")
  },
  {
    name: "partners-slug",
    path: "/partners/:slug()",
    meta: _91slug_93nVEWT12ylwMeta || {},
    component: () => import("/opt/build/repo/pages/partners/[slug].vue")
  },
  {
    name: "pl-slug",
    path: "/pl/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/pl/[...slug].vue")
  },
  {
    name: "supplier-manage-booking-orderReference-email",
    path: "/supplier/manage-booking/:orderReference()/:email()",
    component: () => import("/opt/build/repo/pages/supplier/manage-booking/[orderReference]/[email].vue")
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/2-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/4-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/6-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/8-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/10-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/12-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/14-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/16-yard-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/knowledge/do-you-need-insurance-to-hire-a-mini-digger-",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/categories/skips",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/equipment/categories/waste/skips",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/knowledge/what-can-I-put-into-a-skip",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/knowledge/what-can-i-put-into-a-skip",
    component: component_45stubRU5sOBTecH
  }
]