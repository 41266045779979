export default defineNuxtRouteMiddleware((to, from) => {
  // Redirect /pl/skip-hire to /sl/skip-hire
  if (to.path.startsWith("/pl/skip-hire")) {
    const newPath = to.path.replace("/pl/", "/sl/");
    return navigateTo(newPath, { redirectCode: 301 });
  }
  
  // Redirect old location URLs to new skip hire URLs
  if (to.path.startsWith("/locations/category/skips/")) {
    const location = to.path.split('/').pop(); // gets the last segment (e.g., 'maldon')
    return navigateTo(`/sl/skip-hire-${location}`, { redirectCode: 301 });
  }
});
